.cohort-input {
	.form-floating:first-child {
		select {
			border-right: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.form-floating:last-child {
		select {
			border-left: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}