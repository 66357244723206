@import "./assets/sass/variables";
/* 
 * Import Bootstrap
 */

@import "~bootstrap/scss/bootstrap";
@import 'bootstrap-icons/font/bootstrap-icons.css';

/* 
 * Global App Styles
 */
@import "./assets/sass/accordions";
@import "./assets/sass/buttons";
@import "./assets/sass/cards";
@import "./assets/sass/carousel";
@import "./assets/sass/forms";
@import "./assets/sass/gradient-text";
@import "./assets/sass/icons";
@import "./assets/sass/modals";
@import "./assets/sass/nav";
@import "./assets/sass/table";
@import "./assets/sass/toasts";
@import "./assets/sass/wp";

@import "./components/CohortField/CohortField.scss";

.App {
	min-height: 100vh;
}

#user_switching_switch_on {
	padding: $spacer !important;
	background-color: $darkred;
	margin-bottom: 0 !important;
    position: fixed !important;
	bottom: 0 !important;
	left: 0 !important;
    width: 100%;

	a {
		color: $white;
		text-decoration: underline;
	}
}

iframe {
	max-width: 100%;
}

.aligncenter {
	text-align: center;
}

@include media-breakpoint-down(md) {
	main#content > main {
		margin-left: -$spacer;
		margin-right: -$spacer;
	}
}