.card {
	overflow: hidden;
}

.card-header {
	padding-top: $spacer;
	padding-bottom: $spacer;

	.card-title {
		margin-bottom: 0;
		color: $neutral1;
	}
}

.nav-tabs + .card {
	border-top-left-radius: 0;
}