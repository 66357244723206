/*
 * Customize Bootstrap theme.
 * Per docs, customizations must come before importing Bootstrap styles.
 * Docs suggest putting these customizations and importing Bootstrap scss in a [root]/scss/custom.scss folder.
 * But I'm putting it here to avoid creating excess directories for a line or two of code for now.
 * Find theme variables and details on customizing the theme here:
 * https://getbootstrap.com/docs/5.1/customize/sass/
 */
@import "bootstrap/scss/functions";

// Override Bootstrap defaults
// as of Boostrap 5.2 variable overrides must be declared before BS imports
$primary: #2C4C7B;
$primary-light: #3963A1;
$secondary: #484180;
$body-bg: #f2f4f7;
$body-color: #595A62;
$primary-bg-subtle: #f5f6f9;

$info-bg-subtle: #F4F5F5;
$info-border-subtle: transparent;
$info-text-emphasis: #2B313F;

$table-bg: #fff;

@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";

$font-family-sans-serif: "Outfit", system-ui, -apple-system, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight: 700;
$line-height-base: 1.4;

// Set standard color vars
$secondary5: #4098D7;
$darkblue: #366893;
$blue: #227AB9;
$staffblue: #366893;
$lightblue: #489FDF;
$lighterblue: #DCEEFB;
$lightpink: #EAE2F8;
$purple: #83627b;
$pink: #653CAD;
$neutral1: #102A43;
$neutral2: #334E68;
$neutral3: #486581;
$neutral4: #627D98;
$neutral5: #829AB1;
$neutral8: #D9E2EC;
$neutral9: #F0F4F8;
$white: #ffffff;
$green: #57AE5B;
$lightgreen: #8fb20d;
$darkgreen: #3f6634;
$yellow: #ebbd2a;
$lightred: #FACDCD;
$darkred: #780A0A;
$gold: #F9DA8B;
$mustard: #513C06;

$success: #217F2F;

// NEW EQQO THEME VARS
// TODO: clean up previous, unused vars
$eqqo-background-color: #D0E6FF66;
$eqqo-black: #0D213C;
$eqqo-blue: #2C4C7B;
$eqqo-dark-gray: #595A62;
$eqqo-gray: #d3d6db;
$eqqo-light-gray: #E3E5E8;
$eqqo-magenta: #C4327C;
$eqqo-pale-gray: #E8EFF9;
$eqqo-purple: #484180;
$text-color-light: #fcfcfc;

$eqqo-magenta-purple-gradient: linear-gradient(90deg, #CE317C 0%, #484180 100%);
$eqqo-blue-gradient: linear-gradient(83.8deg, #09208E 23.19%, #4187C1 115.3%);


// Add new colors to bootstrap css classes (ie, bg-darkblue, text-darkblue now exist
$theme-colors: map-merge($theme-colors, ("darkblue": $darkblue,
      "blue": $blue,
      "lightblue": $lightblue,
      "lighterblue": $lighterblue,
      "neutral1": $neutral1,
      "neutral2": $neutral2,
      "neutral3": $neutral3,
      "neutral4": $neutral4,
      "neutral5": $neutral5,
      "neutral8": $neutral8,
      "neutral9": $neutral9,
      "white": $white,
      "pink": $pink,
      "lightpink": $lightpink,
      "green": $green,
      "lightgreen": $lightgreen,
      "darkgreen": $darkgreen,
      "gold": $gold,
      "mustard": $mustard,
      "lightred": $lightred,
      "darkred": $darkred,
      "secondary5": $secondary5,
      "staffblue": $staffblue,
      // new EQQO vars added to theme here
	  "eqqo-background-color": $eqqo-background-color,
      "eqqo-black": $eqqo-black,
      "eqqo-light-gray": $eqqo-light-gray,
      "eqqo-magenta": $eqqo-magenta,
      "eqqo-purple": $eqqo-purple,
      "eqqo-pale-gray": $eqqo-pale-gray,
      "eqqo-gray": $eqqo-gray,
      "eqqo-dark-gray": $eqqo-dark-gray,
      "text-color-light": $text-color-light ));

// override maps
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

// Link and button colors
$link-color: $primary;
$link-decoration: none;
$link-hover-color: $primary;
$btn-link-color: $link-color;
$btn-link-hover-color: $link-hover-color;

$font-sizes: map-merge($font-sizes, (12: 12px,
      14: 14px,
      16: 16px,
      18: 18px,
      24: 24px,
      32: 32px,
      48: 48px));

// Border radius
$border-radius: 8px;
$border-radius-sm: 4px;
$border-radius-lg: 16px;
$border-radius-pill: 32px;

$btn-border-radius: $border-radius-pill;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;

// Cards
$card-title-spacer-y: $spacer;
$card-border-radius: $border-radius-lg;
$card-box-shadow: 0px 2px 6px;
$card-cap-bg: $white;

// Tables
$table-striped-bg: $lighterblue;

// Navigation
$nav-tabs-border-radius: $border-radius-lg;

// Forms
$form-check-min-height: 20px;
$form-switch-width: $form-check-min-height * 2;
$form-check-input-checked-border-color: transparent;
$form-check-input-checked-bg-color: $primary;
$form-switch-checked-color: $green;

$modal-content-border-radius: 12px;
$modal-header-padding-y: $spacer * 1.5;

// remaining bootstrap imports
@import "bootstrap/scss/mixins";