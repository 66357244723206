@use "sass:color";
@import "../assets/sass/variables";
@import "../assets/sass/utilities";

html,
body {
	font-family: $font-family-sans-serif;
	line-height: $line-height-base;
}

h1 {
	color: var(--bs-primary);
	font-weight: $headings-font-weight;
}

h2,
h3,
h4,
h5,
h6 {
	color: var(--bs-primary);
	font-weight: 600;
}

img {
	display: block;
	margin: 0;
	max-width: 100%;
	object-fit: contain;
}

// hide number input buttons
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.labeled-divider {
	align-items: center;
	display: flex;
	margin-block: calc(var(--flow-space, 1rem) * 2); // use flow spacing if we're in it, or 1rem, times 2 to create extra visual space

	&::before,
	&::after {
		background-color: rgba(0, 0, 0, 0.16);
		content: " ";
		flex: 1 2 33%;
		height: 1px;
	}

	&::before {
	margin-inline-end: 1rem;
	}

	&::after {
	margin-inline-start: 1rem;
	}

	+ * {
	margin-top: 0; // keep spacing before and after divider equal
	}
}

.sign-up {
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 100lvh;

	&:has(.container) {
		padding-block: 2rem;
	}

	&.cover {
		height: 100lvh;
	}

	h1 {
		margin-block: 0.75rem 2.5rem;
	}

	> div {
		align-items: center;
		display: flex;
		height: 50%;
		flex-direction: column;
		justify-content: center;
	}

	&-content {
		--flow-space: 2rem;
		align-items: start !important;
		background-color: #fff;
		order: 2;
		padding: 2rem;
	}

	&-decoration {
		background-color: #e3e5e8;
		order: 1; // place first on mobile
	}

	.logo {
		display: block;
		height: auto;
		margin: 0;
		width: 236px;
	}

	.wrapper {
		max-width: 728px;
	}

	.container {
		border: 1px solid var(--bs-eqqo-pale-gray);
		border-radius: $border-radius-lg; // TODO: use conditional border radius technique for this
		padding: 2rem;
	}

	.create-account {
		form {
			text-align: left;

			.form-label {
				font-weight: 600;

				&:not(:first-of-type) {
					margin-top: 1.5rem;
				}
			}

			.row {
				align-items: end;
				&:not(:last-child) {
					margin-bottom: 1.5rem;
				}
			}

			.accordion {
				--accordion-border-radius: 0;
				--bs-accordion-bg: transparent;
				--bs-accordion-active-bg: transparent;
				// --bs-accordion-btn-padding-y: 0;
				--bs-accordion-btn-padding-x: 0;
				--bs-accordion-body-padding-y: 1.5rem;
				--bs-accordion-body-padding-x: 0;

				&-item {
					border: 0;
				}

				&-button {
					box-shadow: none;
				}
			}
		}
	}
}

.sign-up-steps {
	> div {
		align-items: center;
		display: flex;
		flex-basis: 33.333%;
		flex-direction: column;
		max-width: 96px;

		&::after {
			align-items: center;
			aspect-ratio: 1;
			background-color: var(--bs-eqqo-light-gray);
			border-radius: 50%;
			content: attr(data-number);
			display: flex;
			font-size: 1em;
			height: 52px;
			justify-content: center;
			margin-top: 0.5rem;
			padding: 1rem;
			width: 52px;
		}

		&[data-current="true"] {
			color: var(--bs-primary);
			font-weight: 700;
			&::after {
				background-color: var(--bs-primary);
				color: var(--bs-white);
			}
		}

		&[data-complete="true"] {
			color: var(--bs-success);
			&::after {
				background-color: var(--bs-success);
				color: var(--bs-white);
				content: url('../assets/check.svg');
			}
		}
	}
}

.nav-menu-button {
	border-radius: $border-radius-sm;
	padding: 0.5rem 1rem;
}

.profile {
	min-height: 100lvh;
}

.profile-page-top-header {
	gap: 1rem 0.5rem;

	h1 {
		margin: 0;
		order: 3;
		width: 100%;
	}
}

.profile__sidebar {
	display: none;
}

.profile__content {
	--bs-gutter-x: 2rem;
	margin-bottom: 2rem;

	> .container {
		--bs-gutter-y: 1rem;
		--bs-gutter-x: 2rem;
		max-width: 1052px;
		padding-block: var(--bs-gutter-y);

		// this is the main content container, has very subtle box shadow
		&.bg-white {
			box-shadow: 0 0 16px rgba(0,0,0,0.025);
		}
	}

	.video {
		align-items: center;
		color: #fff;
		display: flex;
		justify-content: center;
	}
}

.assessment-view {
	.assessment-section-header {
		align-items: center;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin-bottom: 1.5rem;

		> * {
			margin-bottom: 0;
		}
	}

	.assessment-actions {
		margin-top: 2rem;
	}

	hr {
		margin-block: 2rem;
	}
}

.assessment-question {
	position: relative;

	> * {
		margin: 0;
		position: relative;
		z-index: 1;
	}

	&.active {
		p {
			color: var(--bs-primary);
		}

		&::after {
			background-color: $eqqo-background-color;
			border-radius: 8px;
			content: " ";
			display: block;
			inset: -0.5rem -1rem -0.75rem;
			pointer-events: none;
			position: absolute;
			z-index: 0;
		}
	}
	.btn-group {
		gap: 1rem;
		z-index: 1;

		label {
			background-color: var(--bs-white);
			border-radius: 0.5rem !important;
			flex-basis: 20%;
			font-weight: 600;
			justify-content: center;
		}
	}
	.btn-outline {
		border-color: var(--bs-eqqo-light-gray);
		color: var(--bs-primary);
		font-size: 14px;
		padding: 0.75rem;
	}
	.btn-check + .btn:hover {
		background-color: var(--bs-white);
		border-color: var(--bs-primary);
		color: var(--bs-primary);
	}
	// selected state for rating scale buttons
	.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
		background-color: $primary-light;
		border-color: $primary-light;
		color: var(--bs-white);
	}
}

.profile__instructions {
	border-radius: $border-radius-lg;

	h2 {
		font-size: 21px;
	}

	form {
		border-radius: $border-radius;
	}
}

.profile-report__nav {
	background-color: var(--bs-eqqo-black);
	color: var(--bs-white);
	position: sticky;
	bottom: 0;

	.btn-gradient {
		border: 4px solid var(--bs-eqqo-blue-white);
		margin-top: -100%;
		padding: 1rem;

		span {
			font-size: 0;
		}
	}

	.nav-item {
		a {
			font-size: 12px;
		}
		svg {
			height: 1em;
			width: 1em;
		}
	} 
}

.eqqo-score-bar {
	--bs-gutter-x: 0;

	img,
	p {
		margin: 0 auto;
	}

	canvas {
		margin: auto 0;
		max-height: 72px;
	}
}

.chart-legend {
	ul {
		display: flex;
		font-size: 14px;
		gap: 0.5rem 1rem;
		list-style-type: none;
	}

	li {
		align-items: center;
		display: flex;
		gap: 0 0.325em;

		&::before {
			background-color: var(--legend-item-color);
			border-radius: 0.5rem;
			content: " ";
			display: inline-block;
			height: 1em;
			width: 1em;
		}

		&[data-type="line"] {
			&::before {
			border-radius: 0;
			height: 2px;
			}
		}
	}
}

// EQQO REPORT HEADER
.profile__header {
	--eqqo-brand-gradient: linear-gradient(90deg, #ce317c 0%, #484180 100%);
	background-image: var(--eqqo-brand-gradient);
	color: var(--bs-white);

	a {
		color: var(--bs-white);
	}

	h1 {
		font-size: 23px;
	}
}

@media screen and (min-width: breakpoint-min("md")) {
	.sign-up {
		> div {
			height: 100%;
			order: unset;
		}
	}

	.profile__content {
		> .container {
			--bs-gutter-y: 2rem;
			--bs-gutter-x: 4rem;
		}
	}
}

@media screen and (min-width: breakpoint-min("md")) {
	.profile-page-top-header {
		h1 {
			font-size: 32px;
			order: unset;
			width: auto;
		}
	}
}

@media screen and (min-width: breakpoint-min("lg")) {
	.nav-menu-button {
		display: none;
	}

	.profile__sidebar {
		display: block;
		height: 100lvh;
		position: sticky;
		top: 0;
	}

	.profile__content {
		--bs-gutter-x: 4rem;
	}
}