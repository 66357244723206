.btn {
	align-items: center;
	border: 0;
	display: inline-flex;
	gap: 0 0.5em;
	padding: 0.875rem 2rem;
}

.btn-outline {
	border: 1px solid var(--bs-btn-border-color);
}

.btn-lighterblue {
	color: $link-color;
}

.btn-outline-primary {
	background-color: $lighterblue;
}

.btn-gradient {
	background-image: $eqqo-blue-gradient;
	color: var(--bs-white);
	font-size: 1rem;
	font-weight: 500;

	&:hover,
	&:focus {
		background-image: linear-gradient(91deg, #1D70AF 0%, #6D6CE4 100%);
		color: var(--bs-white);
	}
}

.btn.w-100 {
	justify-content: center;
	text-align: center;
}