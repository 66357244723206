.toast {
	&.bg-success,
	&.bg-danger {
		color: $white;
	}
}

#site-notifications {
	z-index: 999999;
	
	.toast {
		.progress {
			border-radius: 0;
		}
	}
}