.modal-title {
	color: $neutral2;
	font-size: 18px;
	font-weight: 600;
}

#root {
	transition: .25s filter;
}

body.modal-open {
	#root {
		filter: blur(3px);
	}
}

.modal-backdrop.choose-tier {
	z-index: 1150;

	&+.modal {
		z-index: 1155;
	}
}

