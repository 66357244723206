.input-group {
	&.seamless-icon {
		$iconSize: 1.5rem;
		$iconPadding: $iconSize;

		i {
			font-size: $iconSize;
			font-weight: bold;

			position: absolute;
			z-index: 10;
			top: 50%;
			transform: translateY(-50%);
			left: $iconPadding;
		}

		input.form-control {
			padding-left: ( $iconSize + ($iconPadding * 2) ) !important;
			border: none;
			font-weight: $font-weight-bold;

			border-top-left-radius: $border-radius !important;
			border-bottom-left-radius: $border-radius !important;
		}
	}
}

.form-switch {
	align-items: center;
	display: flex;
	gap: 0.5rem;

	.form-check-input {
		height: 1.5rem;
	}
}

label.required {
	&:after {
		content: " *";
		color: $red;
	}
}

.stripe-card-input {
	padding: ($spacer * .75) $input-padding-x;
	border: 1px solid $input-border-color;
	border-radius: $input-border-radius;
}

.form-control,
.form-select,
.form-check-input:not(:checked) {
	background-color: var(--bs-white);
	&:focus {
		background-color: var(--bs-white);
	}
}