@import "./variables";

.carousel-indicators [data-bs-target] {
    height: 10px;
    width: 10px;
    border-radius: 50%;

	&.active {
		background-color: $primary;
	}
}