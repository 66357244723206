// overrides for some of Bootstrap's styling of accordions
.accordion {
  --accordion-border-radius: 0.5rem;
  
  &.shadow {
    border-radius: var(--accordion-border-radius); // prevent square corners above drop shadow
  }

  .accordion-item {
    &:first-of-type {
      border-top-left-radius: var(--accordion-border-radius);
      border-top-right-radius: var(--accordion-border-radius);

      .accordion-button {
        border-top-left-radius: calc(var(--accordion-border-radius) - 1px);
        border-top-right-radius: calc(var(--accordion-border-radius) - 1px);
      }
    }
    &:last-of-type {
      border-bottom-left-radius: var(--accordion-border-radius);
      border-bottom-right-radius: var(--accordion-border-radius);

      .accordion-button.collapsed {
        border-bottom-left-radius: calc(var(--accordion-border-radius) - 1px);
        border-bottom-right-radius: calc(var(--accordion-border-radius) - 1px);
      }
    }
  }
}