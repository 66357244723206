svg.i {
	&.text-blue {
		* {
			fill: $blue;
		}
	}

	&.text-primary {
		* {
			fill: $primary;
		}
	}
}

.btn, a, span {
	svg.i {
		height: 1em;
	}
}

.i {
	position: relative;

	&.i-2x {
		height: 2em;
	}
}