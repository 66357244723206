$nav-logo-size: 195px;
$nav-block-padding: 16px;

// sidebar nav menu
.profile__nav {
	--bs-gutter-x: 2rem;
	display: flex;
	flex-direction: column;
	gap: 1rem 0;
	height: 100%; // fill whole sidebar
	padding-block: $nav-block-padding;

	.logo {
		flex: 0 1 157px;
		height: auto;
		margin-right: 64px;
		min-width: 0;
	}

	nav {
		height: calc(100% - (#{$nav-block-padding} * 2));
	}
	
	.nav-link {
		align-items: center;
		display: flex;
		gap: 0 0.5rem;
		padding-inline: 0;

		svg {
			height: 1em;
			width: 1em;
		}
	}
}